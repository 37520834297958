import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import TablaNominasCreate from "./TablaNominasCreate";
import { Button, Row, Label, Input, Table, Col, Spinner,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import SweetAlert from "sweetalert2-react";

function NominasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_NOMINAS = process.env.REACT_APP_URL_NOMINAS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [frecuencia, setFrecuencia] = useState("");
  const [fecha, setFecha] = useState("");
  const [text, setText] = useState(false);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        // setColaboradores(allColaboradores);
        let arrayTabla = allColaboradores
        .sort((a, b) => (a.idOrdenCompra > b.idOrdenCompra ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            nombre:a.nombre + " " + a.apellido,
            puesto:a.puestos[0].name,
            sueldo_actual:a.sueldo_actual,
            comentarios:"na",
            is_active: a.is_active,
            planteles: a.planteles[0]._id,
            areas: a.areas[0]._id,
          };
        }).filter(function (el) {
          return el != null;
        });
    
        let dataFinal = Object.values(arrayTabla);
        setColaboradores(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPlanteles = response.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  let SP = 0;
  let SA = 0;

  const pagarNominas = (event) => {
    event.preventDefault();
    if(fecha !=""){
    if (user.planteles == PLANTELES_GENERAL && user.areas == AREAS_GENERAL) {
      SP = selectedPlantel;
      SA = selectedArea;
    } else {
      SA = user.areas;
      SP = user.planteles;
    }
    Swal.fire({
      title: "Estas seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Crear Nomina!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalGenerarCargos = colaboradores.length;
        colaboradores.map((n) => {
          if (
            n.is_active == "Si" &&
            (SP == 0 || SP == n.planteles) &&
            (SA == 0 || SA == n.areas) &&
            (frecuencia == 0 || frecuencia == n.frecuencia)
          ) {
            axios
              .post(
                URL_NOMINAS,
                {
                  fecha,
                  pagado: "No",
                  importe: n.sueldo_actual,
                  colaboradores: n._id,
                  comentarios: n.comentarios,
                  planteles: n.planteles,
                  areas: n.areas,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalGenerarCargos = totalGenerarCargos - 1;
                if (totalGenerarCargos == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Nomina",
                      detalle: `Fecha: ${fecha}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalGenerarCargos = totalGenerarCargos - 1;
          }
        });
      }
    })
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Selecciona una fecha",
    });
  }
  };


  function sueldoModificado(nuevoSueldo, index, sueldoActual) {
    let data = colaboradores;
    if (nuevoSueldo) {
      data[index].sueldo_actual = parseFloat(nuevoSueldo);
      setColaboradores(data);
    }
  }

  function notas(comentarios, index) {
    if(!comentarios){
      return comentarios = "na"
    }
    let notasColaborador = colaboradores;
    notasColaborador[index].comentarios = comentarios;
    setColaboradores(notasColaborador);
  }

  return (
    <>
      <Header />
      <br />
      {user.nominas_create ? (
        <div className="card container col-sm-10">
          <br />
          <Row>
            <Button
              size="sm"
              type="submit"
              className="btn btn-success"
              onClick={pagarNominas}
            >
              Generar Nomina
            </Button>
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button
              size="sm"
              href="/Nominas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
          <h3 align="center">Crear Nominas</h3>
          <Row>
            <Col md={2}>
              <Label>Fecha </Label>
              <Input
                bsSize="sm"
                type="date"
                placeholder="Fecha de Ingreso"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Frecuencia</Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Frecuencia"
                value={frecuencia}
                required
                onChange={(e) => {
                  setFrecuencia(e.target.value);
                }}
              >
                <option value="0">Frecuencia</option>
                <option value="Semanal">Semanal</option>
                <option value="Quincenal">Quincenal</option>
                <option value="Mensual">Mensual</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th style={{ paddingBottom: "0px" }}>Nombre</th>
                {/* <th style={{ paddingBottom: "0px" }}>Plantel</th>
                <th style={{ paddingBottom: "0px" }}>Area</th> */}
                <th style={{ paddingBottom: "0px" }}>Puesto</th>
                <th style={{ paddingBottom: "0px" }}>Sueldo Base</th>
                <th style={{ paddingBottom: "0px" }}>Editar Importe</th>
                <th style={{ paddingBottom: "0px" }}>Comentarios</th>
              </tr>
              {/* <tr>
                <td></td>
                {user.planteles == PLANTELES_GENERAL ? (
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedPlantel}
                      onChange={(e) => setSelectedPlantel(e.target.value)}
                    >
                      <option value="0">Selecciona</option>
                      {planteles
                        .sort((a, b) => a.name - b.name)
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </td>
                ) : (
                  <td></td>
                )}
                {user.areas == AREAS_GENERAL ? (
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => setSelectedArea(e.target.value)}
                    >
                      <option value="0">Selecciona</option>
                      {areas
                        .sort((a, b) => a.name - b.name)
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr> */}
            </thead>
            <tbody>
              {user.planteles == PLANTELES_GENERAL &&
              user.areas == AREAS_GENERAL
                ? colaboradores.map((n, index) => {
                    if (
                      n.is_active == "Si" &&
                      (selectedPlantel == 0 ||
                        selectedPlantel == n.planteles) &&
                      (selectedArea == 0 || selectedArea == n.areas) &&
                      (frecuencia == 0 || frecuencia == n.frecuencia)
                    ) {
                      return (
                        <tr>
                          <TablaNominasCreate
                            nombre={n.nombre}
                            // plantel={n.planteles[0].name}
                            // area={n.areas[0].name}
                            puesto={n.puesto}
                            importe={n.sueldo_actual}
                            id={n._id}
                            key={n._id}
                            index={index}
                            sueldoModificado={sueldoModificado}
                            comentarios={notas}
                          />
                        </tr>
                      );
                    }
                  })
                : colaboradores.map((n, index) => {
                    if (
                      n.is_active == "Si" &&
                      user.planteles == n.planteles &&
                      user.areas == n.areas &&
                      (frecuencia == 0 || frecuencia == n.frecuencia)
                    ) {
                      return (
                        <tr>
                           <TablaNominasCreate
                            nombre={n.nombre}
                            // plantel={n.planteles[0].name}
                            // area={n.areas[0].name}
                            puesto={n.puesto}
                            importe={n.importe}
                            id={n._id}
                            key={n._id}
                            index={index}
                            sueldoModificado={sueldoModificado}
                            comentarios={notas}
                          />
                        </tr>
                      );
                    }
                  })}
            </tbody>
          </Table>{" "}
        <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}
export default NominasCreate;
