import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";


function GastosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [selectedIva, setSelectedIva] = useState("");
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [factura, setFactura] = useState("");
  const [disponible, setDisponible] = useState("");
  const [utilizado, setUtilizado] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedDepartamentoEdit, setSelectedDepartamentoEdit] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [URL, setURL] = useState("");
  const [nuevoDisponible, setNuevoDisponible] = useState("");
  const [nuevoUtilizado, setNuevoUtilizado] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);


  useEffect(() => {
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [iva, total]);

  const calculaIVA = (e) => {
    setSelectedIva(e);
    let ivaTemp = (subtotal * e) / 100
    setIva(ivaTemp)
    setTotal(parseFloat(subtotal) + parseFloat(ivaTemp));
  };

  const calcula = (e) => {
    setSubtotal(e);
    let ivaTemp = (e * selectedIva) / 100
    setIva(ivaTemp)
    setTotal(parseFloat(e) + parseFloat(ivaTemp));
  };

  const saveGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false);
      axios
        .all([
          axios.post(
            URL_GASTOS,
            {
              fecha,
              pagado: "No",
              subtotal,
              iva,
              total,
              descripcion,
              factura,
              departamentos: selectedDepartamento,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Gasto",
              detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} / Proveedor: ${selectedDepartamento}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastos_create ? (
        <div className="container">
          <div className="card container col-sm-8">
            <h3 align="center">Dar de alta un Gasto</h3>
            <Form onSubmit={saveGasto}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label className="mr-sm-2">Departamento</Label>
                    <Input
                      type="select"
                      value={selectedDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedDepartamento(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {departamentos
                        .sort((a, b) =>
                          a.name > b.name ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          )
                        })}
                    </Input>
                  </Col>
                 
                 
                 </Row>
                 <Row>
                  <Col sm={4}>
                  <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion
                </Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
                  </Col>
                  <Col sm={4}>
                  <Label for="exampleEmail" className="mr-sm-2">
                  Factura
                </Label>
                <Input
                  type="text"
                  placeholder="Factura"
                  value={factura}
                  required
                  onChange={(e) => {
                    setFactura(e.target.value);
                  }}
                />
                  </Col>
                  <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
              IVA
            </Label>
            <Input
              type="select"
              placeholder="IVA"
              value={selectedIva}
              required
              onChange={(e) => {
                calculaIVA(e.target.value);
              }}
            >
              <option value="">Selecciona</option>
              <option value="16">Si</option>
              <option value="0">No</option>
              </Input>
              </Col>
                </Row>

                

                <Row>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Subtotal
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Subtotal"
                      value={subtotal}
                      required
                      onChange={(e) => {
                        calcula(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      IVA
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="IVA"
                      value={iva}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Total
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder={total}
                      disabled
                    />
                  </Col>
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/Gastos"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default GastosCreate;
