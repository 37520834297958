import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

import { v4 as uuidv4 } from "uuid";

function ListadoDevolucionesProveedores() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_DEVOLUCIONES_PROVEEDORES = process.env.REACT_APP_URL_DEVOLUCIONES_PROVEEDORES;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES = process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES;

  const [compras, setCompras] = useState([]);
  const [devolucionesProveedores, setDevolucionesProveedores] = useState([]);
  const [articulosOC, setArticulosOC] = useState([]);
  const [ordenesSurtido, setOrdenesSurtido] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [tipos_telas, setTiposTelas] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [pedimento, setPedimento] = useState("");
  const [factura_cliente, setFacturaCliente] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [partida, setPartida] = useState("");
  const [aduana, setAduana] = useState("");
  const [fecha_aduana, setFechaAduana] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_surtido, setNumeroSurtido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");

  const [articulosSurtido, setArticulosSurtido] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOC:"",
      articulos: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      clave:"",
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState('');

    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_DEVOLUCIONES_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDevolucionesProveedores = response.data;
    // Array para pagination
    let arrayTabla = allDevolucionesProveedores
    .sort((a, b) => (a.idDevolucionProveedor > b.idDevolucionProveedor ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        fecha: a.fecha,
        user: a.user[0].nombre + " " + a.user[0].apellido,
        idUser: a.user[0]._id,
        compras: a.compras[0].idCompras,
        idCompra: a.compras[0]._id,
        proveedor: a.compras[0].ordenesCompra[0].proveedores[0].nombre_comercial,
        idProveedor: a.compras[0].ordenesCompra[0].proveedores[0]._id,
        observaciones: a.observaciones,
        numero: a.idDevolucionProveedor
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);
    setDevolucionesProveedores(allDevolucionesProveedores);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const ListaDevoluciones = comments.sort((a, b) =>
    a.idDevolucionProveedor > b.idDevolucionProveedor ? 1 : -1
  );


  function PDFTabla() {
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedor)
      ) {
        return [
          a.fecha,
          a.proveedor,
          a.compras,
          a.user
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);
    doc.text(`Listado de Devoluciones Proveedores`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Compra", "Devolvio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    doc.save(`DevolucionesProveedores.pdf`);
  }

  function excel() {
    const dataExcel = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedor)
      ) {
        return {
          Fecha: a.fecha,
          Proveedor: a.proveedor,
          Compra: a.compras,
          Devolvio: a.user
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDevolucionesProveedores";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDevoluciones",
        sheetFilter: ["Fecha", "Proveedor", "Compra", "Devolvio"],
        sheetHeader: ["Fecha", "Proveedor", "Compra", "Devolvio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaDevoluciones.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedor)
      ) {
        return [
          a.fecha,
          a.proveedor,
          a.compras,
          a.user
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 20, 60, 25);
    doc.text(`Listado de Devoluciones`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Compra", "Devolvio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Devoluciones Proveedores",
          email: mailTo,
          fileName: "ListadoDevolucionesProveedores.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Devoluciones a Proveedores.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Enviado con Exito!",
          showConfirmButton: false,
          timer: 2000,
        });
        setMailTo("")
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }



 async function PDFOC(   
  fecha,
  idProveedor,
  proveedor,
  observaciones,
  numero,
  idPDFOC,
  user,
  surtidos ) {
     
     await axios
      .get(`${URL_ARTICULOS_DEVOLUCIONES_PROVEEDORES}/devolucionProveedor/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data
        let arrayTabla = allArticulosSurtido
        .map((a) => {
          return {
            id:a.inventarios[0]._id,
            articulos: a.inventarios[0].articulos[0].codigo + " " + a.inventarios[0].articulos[0].nombre,
            unidad: a.inventarios[0].articulos[0].unidad,
            colores: a.inventarios[0].colores[0].name,
            cantidad: a.inventarios[0].cantidad,
          };
        }).filter(function (el) {
          return el != null;
        });
    
        let dataFinal = Object.values(arrayTabla);  
        let cantidadRollos = dataFinal.length
    
        let mts = dataFinal.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        let total_importe = 0
        const data = dataFinal.map((a) => {
             total_importe = total_importe + (a.precio * a.cantidad)
            return [
              a.articulos,
              a.colores,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              a.unidad,
             ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Devolución # ${numero}`, 20, 20);
        doc.setFontSize(12)
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedor}`, 20, 30);
        doc.text(`Compra # ${numero}`, 20, 35);
        doc.text(`Metros ${new Intl.NumberFormat("en-US").format(TM)} / Kilos ${new Intl.NumberFormat("en-US").format(TK)} / Rollos ${new Intl.NumberFormat("en-US").format(cantidadRollos)}`, 20, 40)
        doc.autoTable({
          head: [["Articulo","Color", "Cantidad","Unidad"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "Total",
              `${new Intl.NumberFormat("en-US").format(TM)}`,
              "",
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`DevolucionProveedor-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });

     
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Compra", field: "Compras", sortable: true },
    { name: "Devolvio", field: "user", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.user.toLowerCase().includes(search.toLowerCase()) ||
                comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
                comment.compras.toString().includes(search)||
                comment.numero.toString().includes(search)
        );
    }
    if(selectedProveedor){
      computedComments = computedComments.filter((e)=>
      e.idProveedor.includes(selectedProveedor)
      ) 
    } 
    if(selectedUser){
      computedComments = computedComments.filter((e)=>
      e.idUser.includes(selectedUser)
      ) 
    } 

    if(selectedFechaInicio){
      computedComments = computedComments.filter((e)=>
      e.fecha >= selectedFechaInicio
      ) 
    }
  
    if(selectedFechaFin){
      computedComments = computedComments.filter((e)=>
      e.fecha <= selectedFechaFin
      ) 
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "compras" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "compras" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "compras" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, selectedProveedor, selectedUser, selectedFechaInicio, selectedFechaFin]);


 
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.pedidos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/DevolucionesProveedoresCreate"
                >
                  Nueva Devolucion
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Devolucion
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Devolcuiones Proveedores</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Devoluciones Proveedores</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                    <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.proveedor}</td>
                      <td>{a.compras}</td>
                      <td>{a.user}</td>
                      <td>
                          <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) => PDFOC( 
                            a.fecha,
                            a.idProveedor,
                            a.proveedor,
                            a.observaciones,
                            a.numero,
                            a._id,
                            a.user,
                            a.compras )}
                        ><i class="far fa-file-pdf"></i>
                        </Button>
                          
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />


      

      {loader}
    </>
  );
}

export default ListadoDevolucionesProveedores;
