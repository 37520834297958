import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';


function ListadoGastos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [gastos, setGastos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedDepartamentoEdit, setSelectedDepartamentoEdit] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [pagado, setPagado] = useState("");
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalDetalle, setModalDetalle] = useState(false);
  const toggleDetalle = () => setModalDetalle(!modalDetalle);
  const [fechaDetalle, setFechaDetalle] = useState("");
  const [descripcionDetalle, setDescripcionDetalle] = useState("");
  const [facturaDetalle, setFacturaDetalle] = useState("");
  const [subtotalDetalle, setSubtotalDetalle] = useState("");
  const [ivaDetalle, setIvaDetalle] = useState("");
  const [totalDetalle, setTotalDetalle] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [factura, setFactura] = useState("");
  const [selectedIva, setSelectedIva] = useState("");
  const [totalEdit, setTotalEdit] = useState();

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [pagadoAutorizado, setPagadoAutorizado] = useState("");

  const [modalBanco, setModalBanco] = useState(false);
  const toggleBanco = () => setModalBanco(!modalBanco);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);

  const [idAutorizar, setIdAutorizar] = useState("");
  const [totalAutorizar, setTotalAutorizar] = useState(0);
  const [departamentoAutorizar, setDepartamentoAutorizar] = useState(""); 

  const [fechaPago, setFechaPago] = useState(hoy);

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_GASTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        let arrayTabla = allGastos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            if(a.descripcion.includes("Gasto Cont.")){
              return {
                _id: a._id,
                fecha: a.fecha,
                total: a.total,
                pagado: a.pagado,
                descripcion: a.descripcion,
                factura: a.factura,
                subtotal: a.subtotal,
                iva: a.iva,
                departamento: a.departamentos[0].name,
                idDepartamento: a.departamentos[0]._id,
                descripciom: a.descripcion,
               
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setGastos(allGastos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);
  let total = 0;

  let totalTablaMXN = 0;
  let totalTablaUSD = 0;

  function PDFTabla() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedDepartamento == 0 ||
          selectedDepartamento == c.departamentos[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total) 
        return [
          c.fecha,
          c.departamentos[0].name,
          c.descripcion,
          c.factura,
          importePDF,
          c.pagado,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Descripcion", "Factura", "Importe", "Pagado"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        ["", "", "", "Total", "$" + new Intl.NumberFormat("en-US").format(total)],
      ],
      showFoot: "lastPage",
    });
    // doc.save("DetalleGastos.pdf");
        window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = gastos.map((c) => {
      if (
        (selectedDepartamento == 0 ||
          selectedDepartamento == c.departamentos[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        return {
          Fecha: c.fecha,
          Departamento: c.departamentos[0].name,
          Descripcion: c.descripcion,
          Factura: c.factura,
          Importe: c.total,
          Pagado: c.pagado,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleGastos-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleGastos`,
        sheetFilter: [
          "Fecha",
          "Proveedor",
          "Descripcion",
          "Factura",
          "Importe",
          "Pagado",
        ],
        sheetHeader: [
          "Fecha",
          "Proveedor",
          "Descripcion",
          "Factura",
          "Importe",
          "Pagado",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const logo = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedDepartamento == 0 ||
          selectedDepartamento == c.departamentos[0]._id) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (pagadoAutorizado == 0 || pagadoAutorizado == c.pagado)
      ) {
        total = total + c.total;
        const importePDF = "$" + new Intl.NumberFormat("en-US").format(c.total)
        return [
          c.fecha,
          c.departamentos[0].name,
          c.descripcion,
          c.factura,
          importePDF,
          c.pagado,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Proveedor", "Descripcion", "Factura", "Importe", "Pagado"]],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        ["", "", "", "Total", "$" + new Intl.NumberFormat("en-US").format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Gastos",
          email: mailTo,
          fileName: "ListaGastos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaDetalle(fecha, descripcion, factura, subtotal, iva, total) {
    setFechaDetalle(fecha);
    setDescripcionDetalle(descripcion);
    setFacturaDetalle(factura);
    setSubtotalDetalle(subtotal);
    setIvaDetalle(iva);
    setTotalDetalle(total);
    toggleDetalle();
  }
  const calculaIVA = (e) => {
    setSelectedIva(e);
    let ivaTemp = (subtotal * e) / 100
    setIva(ivaTemp)
    setTotalEdit(parseFloat(subtotal) + parseFloat(ivaTemp));
  };

  const calcula = (e) => {
    setSubtotal(e);
    let ivaTemp = (e * selectedIva) / 100
    setIva(ivaTemp)
    setTotalEdit(parseFloat(e) + parseFloat(ivaTemp));
  };

  function jalaEdit(
    idEdit,
    fecha,
    descripcion,
    factura,
    subtotal,
    iva,
    total,
    departamento
  ) {
    setIdEdit(idEdit);
    setFecha(fecha);
    setSelectedDepartamentoEdit(departamento);
    setDescripcion(descripcion);
    setFactura(factura);
    setSubtotal(subtotal);
    setSelectedIva(iva);
    setTotalEdit(total);
    toggleEdit();
  }

  const editGasto = (event) => {
    const URL_GASTOS_EDIT = `${process.env.REACT_APP_URL_GASTOS}/${idEdit}`;
    event.preventDefault();
    axios
      .all([
        axios.patch(
          URL_GASTOS_EDIT,
          {
            fecha,
            subtotal,
            iva,
            total: totalEdit,
            descripcion,
            factura,
            departamentos: selectedDepartamentoEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Gasto",
            detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} / Proveedor: ${selectedDepartamentoEdit}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  function autBanco(id, total, departamento){
    setIdAutorizar(id)
    setTotalAutorizar(total)
    setDepartamentoAutorizar(departamento)
    toggleBanco()
  }

  function autorizarGasto() {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, quiero Autorizar!",
    }).then((result) => {
      if (result.isConfirmed) {


        // Jalo el disponible y utilizado del presupuesto
                axios.patch(
                  `${process.env.REACT_APP_URL_GASTOS}/${idAutorizar}`,
                  {
                    pagado: "Si",
                    bancos: selectedBanco,
                    total: totalAutorizar,
                    fechaPago
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
              .then((data) => {

                axios
                .post(
                  `${URL_MOVIMIENTOS_BANCOS}`,
                  {
                    fecha:fechaPago,
                    importe: -totalAutorizar,
                    tipo:"Egreso",
                    bancos: selectedBanco,
                    gastos:data.data._id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )

                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Autorizo Gasto",
                    detalle: `Gasto: ${idAutorizar} / Departamento:${departamentoAutorizar}  / Total:${totalAutorizar}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
              });


        //then de confirmed
      } //cierra el if de confirmado
    }); // cierra el then
  } //cierra la funcion

  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Proveedor", field: "departamento", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    { name: "Factura", field: "factura", sortable: true },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Pagado", field: "pagado", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.pagado.toLowerCase().includes(search.toLowerCase()) ||
          comment.factura.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }

    if (pagadoAutorizado) {
      computedComments = computedComments.filter((e) =>
        e.pagado.includes(pagadoAutorizado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedDepartamento,
    pagadoAutorizado,
    selectedFechaInicio,
    selectedFechaFin,
  ]);




  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                  {user.gastos_create ? (
                      <Button
                        size="sm"
                        href="/GastosCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    )}
                    {/* <Button
                      size="sm"
                      href="/MenuGastos"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button> */}
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Gastos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Gastos</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <tr>
                    <td></td>
                    
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={selectedDepartamento}
                        required
                        onChange={(e) => {
                          setSelectedDepartamento(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {departamentos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ paddingTop: "0px" }}>
                      <Input
                        bsSize="sm"
                        type="select"
                        value={pagadoAutorizado}
                        onChange={(e) => {
                          setPagadoAutorizado(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value="">Selecciona</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </Input>
                    </td>
                    <td style={{ paddingTop: "0px" }}></td>
                  </tr>
                  <tbody>
                    {commentsData.map((c) => {
                      totalTablaMXN = totalTablaMXN + c.total
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.departamento}</td>
                          <td>{c.descripcion}</td>
                          <td>{c.factura}</td>
                          <td>
                            {"$" +
                              new Intl.NumberFormat("en-US").format(c.total)}
                          </td>
                          <td>{c.pagado}</td>
                          <td>
                            {user.gastosCreate && c.pagado == "No" ? (
                              <Button
                                color="primary"
                                size="sm"
                                id="Autorizar"
                                onClick={(e) =>
                                  autBanco(
                                    c._id,
                                    c.total,
                                    c.departamento,
                                  )
                                }
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                            ) : undefined}
                            <Button
                              color="success"
                              size="sm"
                              onClick={(e) =>
                                jalaDetalle(
                                  c.fecha,
                                  c.descripcion,
                                  c.factura,
                                  c.subtotal,
                                  c.iva,
                                  c.total
                                )
                              }
                              id="Detalle"
                            >
                              <i class="fas fa-file-alt"></i>
                            </Button>

                            {c.pagado == "No" ? (
                              <span>
                                {user.gastosCreate ? (
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      jalaEdit(
                                        c._id,
                                        c.fecha,
                                        c.descripcion,
                                        c.factura,
                                        c.subtotal,
                                        c.iva,
                                        c.total,
                                        c.idDepartamento
                                      )
                                    }
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                ) : (
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    disabled
                                  >
                                    <i class="far fa-edit"></i>
                                  </Button>
                                )}
                              </span>
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    })}
                    
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {"$" + new Intl.NumberFormat("en-US").format(totalTablaMXN)}
                      </td>
                      <td className="negrita">
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal size="sm" isOpen={modalDetalle} toggle={toggleDetalle}>
            <ModalHeader toggle={toggleDetalle}>
              Descripción del Gasto
            </ModalHeader>
            <ModalBody>
              <div className="content">
                <div className="card container">
                  <Table size="sm" borderless>
                    <tbody>
                      <tr>
                        <td>Fecha</td>
                        <td>{fechaDetalle}</td>
                      </tr>
                      <tr>
                        <td>Descripcion</td>
                        <td>{descripcionDetalle}</td>
                      </tr>
                      <tr>
                        <td>Factura</td>
                        <td>{facturaDetalle}</td>
                      </tr>
                      <tr>
                        <td>Subtotal</td>
                        <td>
                          {"$" +
                            new Intl.NumberFormat("en-US").format(
                              subtotalDetalle
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td>IVA</td>
                        <td>
                          {"$" +
                            new Intl.NumberFormat("en-US").format(ivaDetalle)}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>
                          {"$" +
                            new Intl.NumberFormat("en-US").format(totalDetalle)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> Editar Gasto</h4>
            </ModalHeader>
            <ModalBody>
            <Row>
                  <Col sm={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={6}>
                    <Label className="mr-sm-2">Departamento</Label>
                    <Input
                      type="select"
                      value={selectedDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedDepartamentoEdit(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {departamentos
                        .sort((a, b) =>
                          a.name > b.name ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          )
                        })}
                    </Input>
                  </Col>
                 
                 </Row>
                 <Row>
                  <Col sm={4}>
                  <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion
                </Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
                  </Col>
                  <Col sm={4}>
                  <Label for="exampleEmail" className="mr-sm-2">
                  Factura
                </Label>
                <Input
                  type="text"
                  placeholder="Factura"
                  value={factura}
                  required
                  onChange={(e) => {
                    setFactura(e.target.value);
                  }}
                />
                  </Col>
                  <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
              IVA
            </Label>
            <Input
              type="select"
              placeholder="IVA"
              value={selectedIva}
              required
              onChange={(e) => {
                calculaIVA(e.target.value);
              }}
            >
              <option value="">Selecciona</option>
              <option value="16">Si</option>
              <option value="0">No</option>
              </Input>
              </Col>
                </Row>

                

                <Row>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Subtotal
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Subtotal"
                      value={subtotal}
                      required
                      onChange={(e) => {
                        calcula(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      IVA
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="IVA"
                      value={iva}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Total
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder={totalEdit}
                      disabled
                    />
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editGasto}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>


          <Modal size="sm" isOpen={modalBanco} toggle={toggleBanco}>
            <ModalHeader toggle={toggleBanco}>
              <h4>Autorizar Gasto</h4>
            </ModalHeader>
            <ModalBody>

            <Col sm={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="FechaPago"
                      value={fechaPago}
                      required
                      onChange={(e) => {
                        setFechaPago(e.target.value);
                      }}
                    />
                  </Col>

            <Col md={6}>
                    <Label className="mr-sm-2">Banco</Label>
                    <Input
                      type="select"
                      value={selectedBanco}
                      onChange={(e) => {
                        setSelectedBanco(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.banco} {a.cuenta}
                            </option>
                          )
                        })}
                    </Input>
                  </Col>


            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={autorizarGasto}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoGastos;
