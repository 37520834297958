import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState([]);  
  
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [colores, setColores] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [factura_proveedor, setFacturaProveedor] = useState("NA");
  const [articulo, setArticulo] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  const [pais_origen, setPaisOrigen] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_dinero, setTotalDinero] = useState(0);
  const [total_rollos, setTotalRollos] = useState(0); 
  const [tipoCambio, setTipoCambio] = useState(1);

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulosOC2, setArticulosOC2] = useState([]);
  const [fecha_orden_compra, setFechaOrdenCompra] = useState("");
  const [plazo, setPlazo] = useState("");
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [total_kilos_orden, setTotalKilosOrden] = useState(0);
  const [total_metros_orden, setTotalMetrosOrden] = useState(0);

  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden:"",
      articulos: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      clave:"0",
      captura:1,
      costo:0,
      gastoUnitario:0,
      total:0,
      articulosName: "",
      coloresName: "",
      rollos: parseFloat(1)
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_ORDENES_COMPRA}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allOrdenesCompra = res.data;
      setOrdenesCompra(allOrdenesCompra);
    })
    .catch((err) => {
      console.log(err);
    });
    
      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    
      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveCompraNueva = async (event) => {
    event.preventDefault();
    if (fecha != "" && observaciones != "" && factura_proveedor !="" && articulo !="") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length + cantidadAgrupado.length + cantidadAgrupado.length
      try {
        await axios
        .post(
          URL_COMPRAS,
          {
            fecha,
            ordenesCompra: selectedOrdenCompra,
            factura_proveedor,
            articulo,
            observaciones,
            total_kilos,
            total_metros: total_metros.toFixed(2),
            user: user.id,
            pais_origen: "NA",
            total_costo: total_dinero.toFixed(2),
            saldo: total_dinero.toFixed(2),
            proveedores: proveedorOrden,
            tipo:"Nacional"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then((data) => {
            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad !=0) {
                axios.post(
                  URL_INVENTARIOS,
                  {
                    compras: data.data._id,
                    articulos: a.articulos,
                    articulosOC: a.articulosOrden,
                    colores: a.colores,
                    cantidad: parseFloat(a.cantidad),
                    clave: a.clave,
                    captura: a.captura,
                    costo: a.costo,
                    proveedores: proveedorOrden,
                    precioMX: a.costo * tipoCambio,
                    tipoCambio,
                    gastoUnitario: a.gastoUnitario
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios.post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                          },
                        }
                      )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 3000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Compra",
                      detalle: `OC ${selectedOrdenCompra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            cantidadAgrupado.map((w) => {
              axios
                .patch(
                  `${URL_ARTICULOS_OC}editRecibido/${w.articulosOrden}`,
                  {
                    cantidad: w.cantidad,
                    ordenesCompra: selectedOrdenCompra
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `OC ${selectedOrdenCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      }
                    )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 3000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Pegamos a Compras Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS}`,
                  {
                    compras: data.data._id,
                    articulos: w.articulos,
                    articulosOC: w.articulosOrden,
                    colores: w.colores,
                    cantidad: w.cantidad,
                    ordenesCompra: selectedOrdenCompra, 
                    fecha,
                    proveedores: proveedorOrden,
                    total: w.cantidad * w.costo,
                    precio: w.costo,
                    gastoUnitario: w.gastoUnitario,
                    precioMX: w.costo * tipoCambio,
                    tipoCambio
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `OC ${selectedOrdenCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      }
                    )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 3000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a compras acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };


  const handleAddFields = () => {
    let TC = totalCaptura +1
    const ultimo = inputFields[inputFields.length-1];
    
    setTotalCaptura(TC)
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOrden: ultimo.articulosOrden,
        articulos: ultimo.articulos,
        unidad:ultimo.unidad,
        colores: ultimo.colores,
        cantidad: 0,
        clave: ultimo.clave,
        captura:TC,
        costo:ultimo.costo,
        gastoUnitario:ultimo.gastoUnitario,
        total:0,
        articulosName: ultimo.articulosName,
        coloresName: ultimo.coloresName,
        rollos: parseFloat(1)
      },
    ]);
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let totalDin = values.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalDinero(Din);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo)
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaArticulo(id, event){
    articulosOC.map((a)=>{
      if(a._id == event.target.value){
        let articulosOrden = a._id
        let idArticulo = a.articulos[0]._id
        let unidadArticulo = a.articulos[0].unidad
        let color = a.colores[0]._id
        let costo = a.precio
        let articulosName = a.articulos[0].nombre
        let coloresName = a.colores[0].name
        handleChangeInputArticulo(id, articulosOrden, idArticulo, unidadArticulo, color, costo, articulosName, coloresName)
      }
    })
  }

  const handleChangeInputArticulo = (id, articulosOrden, idArticulo, unidadArticulo, color, costo, articulosName, coloresName) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden
        i.articulos = idArticulo
        i.unidad = unidadArticulo
        i.colores = color
        i.costo = costo
        i.articulosName = articulosName
        i.coloresName = coloresName
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
    
  };

  function ActualizaTotales(){
        let mts = inputFields.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = inputFields.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);

        let totalDin = inputFields.map((c) => parseFloat(c.total));
        let Din = totalDin.reduce((t, total, index) => t + total, 0);
        setTotalDinero(Din);

        let totalRollos = inputFields.map((c) => parseFloat(c.rollos));
        let Rollos = totalRollos.reduce((t, total, index) => t + total, 0);
        setTotalRollos(Rollos);

        let agrupado = inputFields.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad: 0,
            total:0,
            rollos:0
          };
          groups[val].cantidad += item.cantidad;
          groups[val].rollos += item.rollos;
          groups[val].total += item.total;
          groups[val].id = item.id;
          groups[val].articulos = item.articulos;
          groups[val].articulosName = item.articulosName;
          groups[val].coloresName = item.coloresName;
          groups[val].unidad = item.unidad;
          groups[val].colores = item.colores;
          groups[val].clave = item.clave;
          groups[val].costo = item.costo;
          groups[val].gastoUnitario = item.gastoUnitario;
          groups[val].articulosOrden = item.articulosOrden;
          return groups;
        }, []);
    
        let CA = Object.values(agrupado);
        setCantidadAgrupado(CA);
  }


  function jalaOrden(e){
    setSelectedOrdenCompra(e)
    
    axios
    .get(`${URL_ARTICULOS_OC}/ordenesCompra/${e}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosOC = res.data;
      setArticulosOC(allArticulosOC);
    })
    .catch((err) => {
      console.log(err);
    });

    ordenesCompra.map((a)=>{
      if(a._id == e){
        setFechaOrdenCompra(a.fecha)
        setPlazo(a.plazo)
        setFechaCompromiso(a.fecha_compromiso)
        setTotalKilosOrden(a.total_kilos)
        setTotalMetrosOrden(a.total_metros)
        setProveedorOrden(a.proveedores[0]._id)
      }
    })
  }
console.log(inputFields)
  return (
    <>
      <Header />
      <br />
      <br />
      {user.compras_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Compra</h3>
          <Form onSubmit={saveCompraNueva}>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Orden de Compra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedOrdenCompra}
                  required
                  onChange={(e) => jalaOrden(e.target.value)}
                >
                  <option value="0">Selecciona una Orden</option>
                  {ordenesCompra
                    .sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))
                    .map((a) => {
                      if(a.status == "Abierto" && a.is_active == "Si"){
                      return <option value={a._id}>{a.idOrdenCompra} {a.numeroInterno}</option>}
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha OC</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_orden_compra}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaOrdenCompra(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  disabled
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Articulo</Label>
                <Input
                  type="text"
                  placeholder="Articulo"
                  value={articulo}
                  required
                  onChange={(e) => {
                    setArticulo(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Factura Proveedor</Label>
                <Input
                  type="text"
                  placeholder="Factura Proveedor"
                  value={factura_proveedor}
                  required
                  onChange={(e) => {
                    setFacturaProveedor(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Tipo Cambio
                </Label>
                <Input
                  type="number"
                  placeholder="TipoCambio"
                  value={tipoCambio}
                  required
                  onChange={(e) => {
                    setTipoCambio(e.target.value);
                  }}
                />
              </Col>
              {/* <Col md={2}>
                <Label>Pais Origen</Label>
                <Input
                  type="text"
                  placeholder="Pais Origen"
                  value={pais_origen}
                  required
                  onChange={(e) => {
                    setPaisOrigen(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              </Row>


                    <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Color</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Rollos</Label>
                </Col>
              </Row>
                {cantidadAgrupado.map((ag)=>{
                  return (
                    <>
                    <Row>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.articulosName}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.coloresName}</Label>
                      </Col>
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.cantidad}</Label>
                      </Col> 
                    <Col md={2}>
                    <Label className="mr-sm-2">{ag.rollos}</Label>
                      </Col> 
                    </Row>
                    </>
                    )
                })}



              <Row>
              <Col md={12}>
              <h4 id="logoutBoton">
              TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg. / {new Intl.NumberFormat("en-US").format(total_metros)} mts. / {new Intl.NumberFormat("en-US").format(total_rollos)} rollos
              </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Clave</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Gasto Unitario</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={4}>
                      <Input
                        bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.articulosOrden}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Articulo</option>
                        {articulosOC
                          .sort(function (x, y) { return y.articulos[0].codigo - x.articulos[0].codigo || x.colores[0]._id - y.colores[0]._id; })
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.articulos[0].codigo} / {a.articulos[0].nombre} / {a.colores[0].name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidad(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="clave"
                        type="text"
                        placeholder="Clave"
                        value={inputField.clave}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="gastoUnitario"
                        type="text"
                        placeholder="gastoUnitario"
                        value={inputField.gastoUnitario}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidad(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={12}>
              <h4 id="logoutBoton">
              TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg. / {new Intl.NumberFormat("en-US").format(total_metros)} mts. / {new Intl.NumberFormat("en-US").format(total_rollos)} rollos
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoCompras"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
       <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasCreate;
